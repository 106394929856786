import { LIKELION_EDU_CAREER } from 'features/klass/consts'

export const KDTCategories = [
  {
    name: '프론트엔드',
    value: 'frontend',
    href: '',
  },
  {
    name: '백엔드 : Python',
    value: 'backendp',
    href: '',
  },
  {
    name: '백엔드 : Java',
    value: 'backendj',
    href: '',
  },
  {
    name: 'iOS 개발',
    value: 'ios',
    href: '',
  },
  {
    name: 'Android 개발',
    value: 'aos',
    href: '',
  },
  {
    name: '데이터 분석',
    value: 'dataanalysis',
    href: '',
  },
  {
    name: 'UX/UI 디자인',
    value: 'design',
    href: '',
  },
  {
    name: '프론트엔드 심화',
    value: 'frontplus',
    href: '',
  },
  {
    name: '백엔드 심화',
    value: 'backplus',
    href: '',
  },
  {
    name: 'AI 웹 서비스 개발',
    value: 'aiw',
    href: '',
  },
  {
    name: '유니티 게임 개발',
    value: 'ugm',
    href: '',
  },
  {
    name: '클라우드 엔지니어링',
    value: 'cld',
    href: '',
  },
]

export const KDTList = [
  {
    title: 'BACK-END SCHOOL',
    subTitle: '다섯달 간의 여정, 백엔드 개발자로 성장하는 지름길',
    desc:
      '기본기에 충실한 프로그래밍 교육과 실전형 프로젝트 경험을 통해 자기 주도적인 주니어 개발자로 성장할 수 있는 백엔드 교육 과정',
    startedAt: '2022.07.06',
    endedAt: '2022.07.20',
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/kdt/backendschool_thumbnail.png',
    mobileImgUrl: 'https://d35ai18pny966l.cloudfront.net/course/kdt/backendschool_og_image_2_1.png',
    url: '/',
    bgColor: '#A6F3C9',
  },
  {
    title: 'FRONT-END SCHOOL',
    subTitle: '기초가 튼튼한 프론트엔드 개발자 양성 교육',
    desc:
      '기본기와 협업 역량을 동시에 갖춘 주니어 프론트엔드 개발자로의 성장. 개발의 핵심 요소에 집중한 커리큘럼과 함께하는 프론트엔드 스쿨이라면 가능합니다.',
    startedAt: '2022.07.06',
    endedAt: '2022.07.21',
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/kdt/frontendschool_thumbnail.png',
    mobileImgUrl:
      'https://d35ai18pny966l.cloudfront.net/course/kdt/frontendschool_og_image_2_1.png',
    url: '/',
    bgColor: '#3CB8FF',
  },
  {
    title: 'AI SCHOOL',
    subTitle: '실무 맞춤 성장 지향형 데이터 분석가 양성 교육',
    desc:
      '이론과 실습, 프로젝트로 이어지는 유기적이고 밀도높은 학습 성장 경험을 통해 데이터 분석가로 발돋움할 수 있는 데이터 분석 핵심 집중 교육 과정',
    startedAt: '2022.07.06',
    endedAt: '2022.06.22',
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/kdt/aischool_thumbnail.png',
    mobileImgUrl: 'https://d35ai18pny966l.cloudfront.net/course/kdt/aischool_og_image_2_1.png',
    url: '/',
    bgColor: '#C5F1F6',
  },
  {
    title: 'START-UP SCHOOL',
    subTitle: '멋쟁이사자처럼 10년 창업 교육 노하우가 응축된 스타트업 빌딩 교육',
    desc:
      '아이디어 기획, 디자인, 가설 검증, 마케팅, 개발까지 한 번에 끝내기! 멋쟁이사자처럼에서 만난 동료들과 함께하는 12주 간 성장 중심 스타트업 빌딩 교육',
    startedAt: '2022.07.06',
    endedAt: '2022.06.23',
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/kdt/startupschool_thumbnail.png',
    mobileImgUrl: 'https://d35ai18pny966l.cloudfront.net/course/kdt/startupschool_og_image_2_1.png',
    url: '/',
    bgColor: '#D1C3FC',
  },
]

export const KDTReasonList = [
  {
    id: 1,
    title: '주 5일 전일제 라이브 교육',
    desc: {
      pc:
        '하루 종일 VOD와 클래스 노트로만 운영되는 보편적인 국비지원 교육의 틀을 깨고, 멋사 국비지원 부트캠프는 주 5일 100% 라이브로 교육합니다.',
      mobile:
        '하루 종일 VOD와 클래스 노트로만 운영되는 보편적인 국비지원 교육의 틀을 깨고, 멋사 국비지원 부트캠프는 주 5일 100% 라이브로 교육합니다.',
    },
    imageUrl: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/KDTInfo_img1-1.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/KDTInfo_img1-1.png',
    },
  },
  {
    id: 2,
    title: `넘볼 수 없는 ${LIKELION_EDU_CAREER}년 교육 노하우`,
    desc: {
      pc: `기본기에 충실한 기초 교육부터 실전형 프로젝트와 해커톤까지 모두 경험할 수 있습니다. ${LIKELION_EDU_CAREER}년 교육 노하우가 담긴 멋사 국비지원 부트캠프 과정은 커리큘럼의 밀도부터 다릅니다.`,
      mobile: `기본기에 충실한 기초 교육부터 실전형 프로젝트와 해커톤까지 모두 경험할 수 있습니다. ${LIKELION_EDU_CAREER}년 교육 노하우가 담긴 멋사 국비지원 부트캠프 과정은 커리큘럼의 밀도부터 다릅니다.`,
    },
    imageUrl: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/KDTInfo_img2-1.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/KDTInfo_img2-1.png',
    },
  },
  {
    id: 3,
    title: '압도적인 수료율이 증명하는 차별화된 케어 시스템',
    desc: {
      pc:
        '상시 모니터링과 밀착 학습 관리를 통해 학생 개개인별로 문제 진단과 피드백을 제공합니다. 멋쟁이사자처럼 운영 시스템은 여러분의 성장을 면밀히 확인하고 즉각적으로 개선합니다.',
      mobile:
        '상시 모니터링과 밀착 학습 관리를 통해 학생 개개인별로 문제 진단과 피드백을 제공합니다. 멋쟁이사자처럼 운영 시스템은 여러분의 성장을 면밀히 확인하고 즉각적으로 개선합니다.',
    },
    imageUrl: {
      pc: 'https://glob-dev-resource.s3.ap-northeast-2.amazonaws.com/course/kdt/KDTInfo_img3-1.png',
      mobile:
        'https://glob-dev-resource.s3.ap-northeast-2.amazonaws.com/course/kdt/KDTInfo_img3-1.png',
    },
  },
  {
    id: 4,
    title: '강하고 끈끈한 멋쟁이사자처럼 네트워크',
    desc: {
      pc:
        '멋쟁이사자처럼부터 이어져 내려온 튼튼한 커뮤니티에서 학습 동료들을 만나보세요. 수료 이후 커뮤니티를 통해 다양한 특강과 이벤트, 취업 기회를 누릴 수 있습니다.',
      mobile:
        '멋쟁이사자처럼부터 이어져 내려온 튼튼한 커뮤니티에서 학습 동료들을 만나보세요. 수료 이후 커뮤니티를 통해 다양한 특강과 이벤트, 취업 기회를 누릴 수 있습니다.',
    },
    imageUrl: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/KDTInfo_img4-1.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/KDTInfo_img4-1.png',
    },
  },
]

export const KDTReviewList = [
  {
    category: 'kdt-ai',
    title: '잠을 포기할 만큼 이 분야가 재밌다고 느꼈습니다',
    review:
      '멋쟁이사자처럼 KDT 스쿨에서 다양한 전공의 사람들과 여러 번 프로젝트를 진행해 보니 할 수 있다는 자신감이 생겼습니다. 멋진 사람들과 함께 공부하니 긍정적인 사고를 배울 수 있었고, 프로젝트를 진행하며 잠을 포기할 만큼 이 분야가 재밌다고 느꼈습니다.',
    student: 'AI 스쿨 5기 수강생',
  },
  {
    category: 'kdt-ai',
    title: '막연하게만 생각해왔던 부분을 실제로 하게 되었습니다',
    review:
      '코딩을 전혀 하지 못했던 제가, 코드를 짜서 팀원들과 공유를 할 수 있을 정도의 실력이 되었습니다. 어렵게만 느껴졌던 머신러닝, 딥러닝 모델들을 이해할 수 있었습니다. 막연하게만 생각해왔던 데이터 분석을 실제로 하고, 이를 이용해서 내가 원하는 결과를 도출하며 스스로 성장했다고 느낍니다.',
    student: 'AI 스쿨 5기 수강생',
  },
  {
    category: 'kdt-ai',
    title: '양질의 프로그램에서 좋은 사람들과 교류하며 성장할 수 있는 의미있는 시간이었습니다',
    review:
      '학습, 프로그램, 커뮤니티 모두 만족스러웠습니다. 운영진 분들께서 양질의 프로그램을 만들기 위해 여러 강사분들을 모셔오거나 좋은 플랫폼을 사용할 수 있게 해주신 점도 매우 좋았습니다. 특히, 실력있고 좋은 사람들과 함께 교류하며 동기부여 받고 스스로 성장할 수 있는 계기를 만든 것 같아 의미있는 시간이었습니다.',
    student: 'AI 스쿨 6기 수강생',
  },
  {
    category: 'kdt-ai',
    title: '모르는 것에 대한 두려움이 사라졌습니다',
    review:
      '모르는 것에 대한 두려움이 많이 사라졌습니다. 모르는 점은 알아가면 되고, 오히려 스스로에 대한 제한을 두지 않았을 때 더 좋은 아이디어들이 많이 나온다는 것을 몸소 체험하게 되었습니다.',
    student: 'AI 스쿨 5기 수강생',
  },
  {
    category: 'kdt-ai',
    title: '어려운 내용을 알차게 배우고 꾸준한 학습 습관도 형성했어요',
    review:
      '혼자서는 공부하기 어려운 부분을 뛰어나신 강사님들 덕분에 굉장히 알차게 배울 수 있었습니다. 이로 인해 혼자서는 막연하게 어떻게 공부해야할지 감이 잡히지 않았었는데, 스스로 학습하는 방법을 배울 수 있었고 꾸준히 학습하는 습관이 잘 형성될 수 있었습니다.',
    student: 'AI 스쿨 6기 수강생',
  },
  {
    category: 'kdt-ai',
    title: '어떤 것을 해야겠다 생각을 하면 손이 움직이고 있는게 가장 신기합니다.',
    review:
      '수강 전에는 매 코드마다 구글링을 하지 않으면 작성을 못 할 정도였는데, 지금은 어떤 것을 해야겠다 생각을 하면 손이 움직이고 있는 게 가장 신기합니다. 코드 작성 문제를 어느 정도 벗어나니 분석과 모델링 단계에서 더 깊은 생각을 하게 된 것 같습니다.',
    student: 'AI 스쿨 5기 수강생',
  },
  {
    category: 'kdt-ai',
    title: '교육기관은 아낌없이 주는 나무같은 멋쟁이사자처럼이 최고입니다',
    review:
      '무엇보다 강사님들과 운영진분들이 강의를 위해 굉장히 노력하는 모습을 보여주십니다. 수강생들도 다들 열정을 가지고 수강신청 했을텐데, 강의를 준비해주시는 분들이 정말 성심성의껏 강의를 준비해주셔서 오히려 그 열정을 따라가는 수강생이 적을 정도입니다. 체계적으로 아낌없이 주는 나무같은 멋쟁이사자처럼 덕분에 양질의 수업을 들을 수 있었습니다. 감사합니다.',
    student: 'AI 스쿨 6기 수강생',
  },
  {
    category: 'kdt-ai',
    title:
      '열정적인 매니저님들과 강사님들, 재미있는 팀원들을 만나 즐거운 기억으로 남을 것 같습니다',
    review:
      '일단 매니저님들께서 열정 넘치게 하나하나 신경 써주시는 게 느껴져 굉장히 감사했습니다. 매니저님과 강사님들의 뜨거운 열정이 수강생에게까지 그대로 전달되는 느낌이었습니다. 그리고 재밌는 팀원 분들과 만나 좋은 분위기 속에서 함께할 수 있었던 것도 좋은 기억으로 남을 것 같습니다.',
    student: 'AI 스쿨 6기 수강생',
  },
  {
    category: 'front',
    title: '개발 지식과 실무 능력을 모두 향상시킬 수 있는 과정',
    review:
      '개발 지식과 실무 능력을 모두 향상시킬 수 있는 과정이었습니다. 수업 외 다양한 스터디와 보충 수업을 통해 개인에게 맞는 역량을 올릴 수 있었습니다. 무엇보다 만족스러웠던 부분은, 실무에서 활용하는 기술 및 다양한 팁을 알려주어 좋았습니다. 값진 시간을 보낼 수 있었고 프론트엔드 개발자를 꿈꾸는 이들에게 추천합니다.',
    student: '프론트엔드 스쿨 1기 수강생',
  },
  {
    category: 'front',
    title: '동료들과 함께 성장할 수 있다는 값진 경험',
    review:
      '혼자 공부하다가 멋쟁이사자처럼의 교육을 듣기 시작한 후 ‘같이’의 가치를 정말 많이 깨달았습니다. 혼자는 할 수 없던 것들도 동료들과 함께하면 할 수 있다는 값진 경험을 할 수 있어서 정말 좋습니다.',
    student: '프론트엔드 스쿨 2기 수강생',
  },
  {
    category: 'front',
    title: '세심하게 설계된 커리큘럼과 수강생 케어 시스템',
    review:
      '저번주에는 손도 못 대던 과제도 이번주에는 수행해나갈 힘이 또 생기는데, 이는 촘촘한 케어 시스템이 있기에 가능한 일이라고 생각합니다. 수강생을 위해 세심하게 설계한 교육이라는 것이 매번 느껴집니다. 자신의 속도를 존중하고, 동료들과 함께 안정감을 기반으로 공부하는 방법은 이 교육에서 가장 크게 얻어갈 자산일 것 같습니다.',
    student: '프론트엔드 스쿨 2기 수강생',
  },
  {
    category: 'front',
    title: '앞으로의 여정에 있어서 정말로 좋은 발판이 된 것 같다',
    review:
      '직접 겪고 나서 보니 얼마나 이 부트캠프를 준비하며 많은 시행착오를 겪고 계속해서 더 좋은 환경을 위해 빌드업해나가고 있는지 느껴졌다. 늘 신경 써주시고 좋은 교육환경을 만들어주셔서 감사합니다. 앞으로의 여정에 있어서 정말로 좋은 발판이 된 것 같습니다.',
    student: '프론트엔드 스쿨 1기 수강생',
  },
  {
    category: 'front',
    title: '정기회고 타임을 통한 교육 완주',
    review:
      '개발문화에서 많이 들어왔지만 전문가를 초빙하여 주기적으로 프로그램을 갖는 것이 신선하였고 회고팀을 따로 만들며 소통하며 느슨한 연결감을 갖게 된 것이 교육을 끝까지 완주하는데에 도움이 많이 되었습니다',
    student: '프론트엔드 스쿨 1기 수강생',
  },
  {
    category: 'front',
    title: '늘 대기하고있는 멋쟁이사자처럼 운영진과 함께한 성장',
    review:
      '필수 교육시간 9-18시 시간외에도 활발히 소통하는 운영부가 최대 장점이었다고 생각합니다. 100% 온라인 교육이라는 것을 알고 기대 없이 시작했는데 온라인 교육도 연결감을 형성할 수 있다는 것에 놀랐습니다. 국비교육치고 이탈자가 정말 적었다고 들었는데 이점이 큰 역할을 한 것 같습니다.',
    student: '프론트엔드 스쿨 1기 수강생',
  },
  {
    category: 'front',
    title: '실무에서 활용하는 기술과 다양한 팁',
    review:
      '국비과정임에도 불구하고 강사님들 그리고 운영진 여러분께서 많은 노력과 서포트를 해주신 덕에 3개월 간 많은 배움을 얻을 수 있었고, 수업 외 다양한 스터디 그리고 보충 수업을 통해 수업을 따라잡을 수 있었습니다. 무엇보다 해당 과정이 만족스러웠던 부분은, 실무에서 활용하는 기술 및 다양한 팁을 알려주는 것이었습니다.',
    student: '프론트엔드 스쿨 1기 수강생',
  },
  {
    category: 'startup',
    title:
      '창업을 통해 나에 대해 생각해보고 앞으로 무엇을 할 수 있는지 진지하게 고민할 수 있었어요',
    review:
      '3개월간 초기 스타트업과 창업에 관해 깊이 생각할 수 있었던 시간이었습니다. 그저 창업이 하고 싶어 무턱대고 신청했었는데, 제가 어떤 사람인지, 어떤 역량이 있는지, 어떤 사람과 잘 맞는지, 무엇을 하고 싶은 사람인지, 앞으로 무엇을 해야 하는지 진지하게 고민하게 되었고 어느 정도 답을 얻어 갈 수 있었습니다.',
    student: '스타트업 스쿨 1기, 수강생',
  },
  {
    category: 'startup',
    title: '스스로 도전하고 깨지는 경험을 통해 능력치를 업그레이드할 수 있습니다',
    review:
      '스타트업 스쿨을 안 했다면 이렇게 많은 감정과 내용 그리고 소중한 사람들을 못 만났을 겁니다. 내용을 배운 것도 너무 소중했지만, 스스로 도전하고, 깨지고, 만나고 헤쳐 나갈 수 있는 경험을 한 것 자체가 능력치로 갖춰진 것 같습니다. 진심으로 감사했고, 너무 소중한 경험이었습니다!',
    student: '스타트업 스쿨 3기, 수강생',
  },
  {
    category: 'startup',
    title: '팀 활동을 통해 MVP를 완성해보며 사업의 경험을 쌓을 수 있어요',
    review:
      '팀 활동을 통해 MVP를 완성해 봄으로써 앞으로 사업을 하는 데 기본이 되는 경험을 쌓을 수 있어요. 창업하려는 사람들과 네트워킹을 할 수 있다는 점에서 훌륭한 프로그램이라 생각하고, 본인 노력 여하에 따라 정말 많은 것을 얻어 갈 수 있습니다. 후속 지원도 계속해 주기 때문에 초보 창업가에게 강력 추천합니다.',
    student: '스타트업 스쿨 2기, 수강생',
  },

  {
    category: 'startup',
    title: '창업을 위한 빠른 프로세스와 개발, 마케팅, 디자인 모든 것을 경험할 수 있어요',
    review:
      '정말 많은 분들을 만나볼 수 있는 기회이고, 창업에 대한 인사이트를 계속해서 늘려나갈 수 있는 좋은 기회라고 생각합니다. 창업을 위한 프로세스를 빠르게 경험할 수 있어요. 개발, 마케팅, 디자인 모든 것을 경험해 볼 수 있습니다. 3개월이라는 기간이 너무 짧게 느껴지네요 너무나 값진 경험 얻고 갑니다!',
    student: '스타트업 스쿨 2기, 수강생',
  },
  {
    category: 'startup',
    title: '창업에 대해 고민이 아닌 행동으로 옮기고 싶다면 스타트업 스쿨로!',
    review:
      '스타트업 스쿨에 있으면서 마인드가 많이 바뀌었고 크게 변화하는 계기가 되었습니다. 창업에 대해서 더 잘 알고 싶어 행동해 보고 싶다면 이 프로그램에 참여해 보기를 추천해 드립니다. 고군분투하던 때가 있었는데, 창업하겠다는 의지를 가진 사람들이 모여 커뮤니티 분위기도 좋았습니다.',
    student: '스타트업 스쿨 3기, 수강생',
  },
  {
    category: 'startup',
    title: '창업은 의지력이 중요하다는 것을 배웠어요',
    review:
      '창업에 대한 진입장벽을 조금이나마 낮춰준 교육이었습니다. 좋은 사람들과 3달간 유익한 시간을 보냈습니다. 창업은 쉽지 않은 과정이고 의지력이 중요하다는 것을 배우고 갑니다.',
    student: '스타트업 스쿨 3기, 수강생',
  },

  {
    category: 'startup',
    title: '창업을 준비한다면 반드시 필요한 프로그램',
    review:
      '창업을 생각한다면 꼭 들어봤으면 하는 프로그램입니다. 창업을 위한 비즈니스 모델과 스타트업 빌딩에 필요한 가치관을 배울 수 있어서 실패를 줄이고 성공하는 창업에 다가갈 수 있어서 좋았습니다. 감사합니다.',
    student: '스타트업 스쿨 2기, 수강생',
  },
  {
    category: 'startup',
    title: '고밀도로 압축된 스타트업 빌딩 과정이에요',
    review:
      '고밀도의 교육 과정 속에 스타트업 자체를 다 녹여낸 교육과정이었습니다. 풀타임으로 해야 하기 때문에 따라가기 힘들 수도 있지만 스스로 많은 발전을 하고 싶다면 이 프로그램을 추천합니다',
    student: '스타트업 스쿨 2기, 수강생',
  },
  {
    category: 'back',
    title: '열정 넘치는 강사와 운영진, 훈련생들과의 커뮤니케이션과 협업',
    review:
      '교육과정이 매우 탄탄하고 강사와 운영진의 열정이 넘쳐 좋은 교육을 받을 수 있었습니다. 특히 5개월 간 다른 교육생들과 꾸준한 커뮤니케이션과 협업이 이루어져 단순한 개발 교육을 뛰어넘는 경험을 할 수 있었습니다.',
    student: '백엔드 스쿨 1기 수강생',
  },
  {
    category: 'back',
    title: '비전공자라도, 경험이 있지만 미숙하더라도 모두가 만족하며 들을 수 있는 교육',
    review:
      'Java, SpringBoot 뿐 아니라 백엔드 개발자로서 가장 중요하다 생각했던 Docker, Jenkins, Linux기반 서버를 다루는 방식에 대해서 정립할 수 있었습니다. 개발이 처음인 비전공자 훈련생도 경험이 있지만 미숙한 훈련생도 모두 만족하며 들을 수 있는 교육이라고 생각합니다. 다음 기수에 지원한다는 동료가 있다면 적극 추천하고 싶습니다. ',
    student: '백엔드 스쿨 1기 수강생',
  },
  {
    category: 'back',
    title: '기초 CS 지식과 언어의 활용 수준이 월등히 상승',
    review:
      '훈련과정을 통해 기초 CS 지식과 Java 언어의 수준이 월등히 올라갔고 처음 배웠던 SpringBoot도 쉽게 다룰 수 있게 되었습니다. 훈련기간동안 즐거웠고 많은 사람들과 행복하게 지낼 수 있었습니다! 이런 멋진 기회를 만들어주신 멋쟁이 사자처럼 강사님, 매니저님, 멘토님, 마지막으로 훈련생 모두 감사했습니다!',
    student: '백엔드 스쿨 1기 수강생',
  },
  {
    category: 'back',
    title: '적극적인 케어와 복습을 위한 자료와 프로젝트',
    review:
      '매일 적극적으로 케어해주시고, 모르는 것이면 바로바로 대답해주셔서 너무 좋았습니다! 질의응답을 위한 시간이 따로 갖추어져 있고 강사님께서 중요 파트는 복습 할 수 있도록 녹화본을 찍어 유튜브에 올려주셔서 도움이 많이 되었습니다. 특히 오후 시간에는 배웠던 내용을 직접 해커톤 프로젝트에 적용해보니 학습 이해도도 상승했습니다.',
    student: '백엔드 스쿨 1기 수강생',
  },
  {
    category: 'back',
    title: '개발자로서 갖추어야하는 기술 역량과 더불어 마인드까지도 배워가는 곳',
    review:
      '강사님의 친절한 질의응답과 세세한 교육 영상이 끝까지 노력할 수 있는 버팀목이 되었습니다. 매니저님들과 함께 고민하시며 피드백을 반영하셨던 모습에 감사했습니다. 전과는 비교도 안 될만큼 성장한 것을 느낄 수 있었고, 개발자로서 갖추어야하는 기술 역량과 더불어 마인드까지도 배워가는 곳이었습니다.',
    student: '백엔드 스쿨 1기 수강생',
  },
  {
    category: 'blockchain',
    title: '스스로 성장하는 것이 느껴지는 재미있는 경험!',
    review:
      '블록체인 스쿨 수강 전 독학을 하려고 많이 노력했는데 체계적으로 공부할 수 있는 방법이 없어 막막했습니다. 그런데 멋사와 함께 공부하니 기초부터 꼼꼼히 다질 수 있었고, 전에는 이해되지 않던 논문도 이해되기 시작했습니다. 블록체인에 대해 넓은 시야를 가질 수 있었고, 스스로 성장하는 것이 느껴지니 재미있는 경험이었습니다. ',
    student: '블록체인 스쿨 1기 수강생',
  },
  {
    category: 'blockchain',
    title: '멋사 블록체인 강의는 탁월한 선택!',
    review:
      '강의를 듣기 전에 저는 자바스크립트 실습 경험이 부족해서 코딩 수업에 대한 걱정이 있었습니다. 일방적인 수업일까 두려웠었는데, 모든 실습 자료를 올려주시고 챌린징한 과제도 있는 등 강의가 너무 재밌고, 소소한 보람을 느낄 수 있었습니다. 멋사라서 그런 걸까요? 타사는 잘 모르겠지만 멋사를 선택하길 너무 잘한 것 같습니다.',
    student: '블록체인 스쿨 1기 수강생',
  },
  {
    category: 'blockchain',
    title: '태어나서 들었던 강의 중 제일 만족스러운 강의!',
    review:
      '태어나서 들었던 강의 중에서 제일 저를 이해를 시키기 위해서 노력하는 것이 느껴졌습니다. 다양한 비유나 표현을 통해 이해시키고, 많은 개념을 학습한 경우 한 번 더 정리해 주고, 적절한 타이밍에 쉬는 시간이 있는 등 모든 것이 정말 좋았습니다. 다양한 방법으로 강의가 효율적으로 진행되어 매우 만족스러웠습니다.',
    student: '블록체인 스쿨 2기 수강생',
  },
  {
    category: 'blockchain',
    title: '강사, 매니저님들 노력 덕분에 끝까지 완주!',
    review:
      '낙오자 없이 함께 이끌고자 하는 강사님과 매니저님들의 노력이 눈에 뚜렷하게 보입니다. 덕분에 힘든 순간이 있어도 끝까지 수강할 수 있었습니다.',
    student: '블록체인 스쿨 2기 수강생',
  },
  {
    category: 'ios',
    title: '적극적인 커뮤니케이션이 좋았습니다',
    review:
      '수강생들과 운영진들과의 커뮤니케이션이 너무 자유롭고 운영진 측에서도 수강생의 의견을 적극적으로 반영해 주는 모습들이 만족스러웠습니다. 특히 운영진, 강사님들의 진행방식에 대해 의문이 생겼을 때 그런 부분을 적극적으로 피드백해 주시며 애자일한 방향으로 부트캠프를 이끌어 나가 주셨습니다. ',
    student: '앱 스쿨 1기 익명',
  },
  {
    category: 'ios',
    title: '함께 하려는 분위기가 좋았습니다',
    review:
      '초반에는 강사님이 기초 스터디를 지속적으로 열어주시어 기초를 탄탄히 공부할 수 있었습니다. 또한 후반부에는 멘토님들께서 추가적으로 알아두면 좋은 기술 스택들을 알려주셨습니다. 서로 포기하지 않고 함께 하려는 분위기가 좋았습니다.',
    student: '앱 스쿨 1기 익명',
  },
  {
    category: 'ios',
    title: '개발자로서의 첫걸음을 잘 내디뎠습니다',
    review:
      '개발 경험이 전무한 상태로 시작했던 부트캠프라 많은 불안감이 있었습니다. 5개월이라는 시간 동안 많은 것을 배우고 다양한 프로젝트도 진행하면서 개발자로서의 첫걸음을 잘 내디딘 것 같습니다. 함께했던 모든 분들 고생 많으셨고, 강사님과 운영진분들, 멘토님들께도 감사의 말씀 드리고 싶습니다.',
    student: '앱 스쿨 1기 익명',
  },
  {
    category: 'ios',
    title: '페어 프로그래밍이 너무 좋았습니다',
    review:
      '혼자 해결하지 못하는 문제로 인해 낑낑거리고 좌절하며 우울할 때가 생겼습니다. 이때 페어 프로그래밍으로 많은 도움을 받았습니다. 앱스쿨을 들으며 동료 간 같이 배워나가고 서로 알려주기도 하면서 성장의 디딤돌이 되었다는 것을 느꼈습니다!',
    student: '앱 스쿨 1기 익명',
  },
  {
    category: 'ios',
    title: '이론보다 실습의 비중이 높아 좋았습니다',
    review:
      '다양한 프로젝트를 진행하면서 코드 협업에 대한 이해도가 많이 올라갔습니다. 이론의 비중보다 실습의 비중이 높아 좋았으며, 다양한 특강이 준비되어 있었고 특히 매주 UI/UX에 대한 강의와 피드백을 받으며 사용자 경험의 중요성에 대해 알게 되어 매우 좋았습니다.',
    student: '앱 스쿨 1기 익명',
  },
  {
    category: 'ios',
    title: '이제 취업이라는 관문이 내게 먼 이야기가 아니라는 것을 느꼈습니다',
    review:
      '과정을 이수하기 이전 iOS에 대해 잘 모르고 수강을 시작했습니다. 강사님께서 못 따라가는 수강생이 있다면 수업이 끝난 뒤에도 별도로 강의를 해주실 정도로 적극적이었습니다. 이를 통해 많은 정보를 얻고 팀 활동을 하는 방식을 배웠으며 취업이라는 관문이 내게 먼 이야기가 아니라는 것을 느꼈습니다. 감사합니다!',
    student: '앱 스쿨 1기 익명',
  },
  {
    category: 'ios',
    title: '취업할 때 꼭 필요한 포트폴리오를 다양한 프로젝트로 구상할 수 있어서 좋았습니다',
    review:
      '강사님의 열정적인 강의를 바탕으로 5개월이라는 시간 동안 많은 것을 배울 수 있어 좋았습니다. 취업할 때 꼭 필요한 포트폴리오를 엡스쿨에서 진행했던 다양한 프로젝트로 구상할 수 있어서 좋았습니다. 혼자라면 이 정도까지 올리지 못했을 실력을 앱스쿨을 하면서 이룰 수 있었습니다.',
    student: '앱 스쿨 1기 익명',
  },
  {
    category: 'ios',
    title: '열정 넘치는 강사님, 열의 있는 학생들이 모여 어마어마한 시너지를 냈던 교육이었습니다.',
    review:
      '단순히 코드를 입력하고 앱을 만들어 보고 끝내는 것이 아닌, 개발자로서 협업과 회고 그리고 동료의 소중함 등을 배울 수 있는 시간이었습니다. 열정 넘치는 강사님, 열의 있는 학생들이 모여서 어마어마한 시너지를 냈던 교육이었다고 생각합니다. 100일간 이곳에서 함께할 수 있어서 너무 감사했고 행복했습니다!',
    student: '앱 스쿨 1기 익명',
  },
]

export const scheduleSchoolList = [
  {
    id: 'frontend',
    label: '프론트엔드',
    active: false,
    bgColor: '#E5F4FF',
    subTitle: '기초가 탄탄한 개발자로 성장',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/frontend_pc2.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/frontend_m2.png',
    },
  },
  {
    id: 'backendp',
    label: '백엔드 : Python',
    active: false,
    bgColor: '#EBF7EB',
    subTitle: '파이썬으로 배우는 백엔드',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/backendp_pc2.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/backendp_m2.png',
    },
  },
  {
    id: 'backendj',
    label: '백엔드 : Java',
    active: false,
    bgColor: '#EBF7EB',
    subTitle: 'Java부터 Spring까지',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/backendj_pc2.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/backendj_m2.png',
    },
  },
  {
    id: 'ios',
    label: 'iOS 개발',
    active: false,
    bgColor: '#E9E5FF',
    subTitle: '실무에 가장 가까운 iOS학습',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/ios_pc2.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/ios_m2.png',
    },
  },
  {
    id: 'aos',
    label: 'Android 개발',
    active: false,
    bgColor: '#E9E5FF',
    subTitle: '기본기와 프로젝트 경험을 함께',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/aos_pc2.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/aos_m2.png',
    },
  },
  {
    id: 'data',
    label: '데이터 분석',
    active: false,
    bgColor: '#CCFBF1',
    subTitle: '데이터 분석가로의 탄탄한 시작점',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/data_pc5.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/data_m5.png',
    },
  },
  {
    id: 'design',
    label: 'UX/UI 디자인',
    active: false,
    bgColor: '#FCE8E6',
    subTitle: '경쟁력있는 커리어의 시작',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/design_pc2.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/design_m2.png',
    },
  },
  {
    id: 'frontendplus',
    label: '프론트엔드 심화',
    active: false,
    bgColor: '#E5F4FF',
    subTitle: '프론트엔드로 한끗 성장을 위해',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/frontendplus_pc2.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/frontendplus_m2.png',
    },
  },
  {
    id: 'backendplus',
    label: '백엔드 심화',
    active: false,
    bgColor: '#EBF7EB',
    subTitle: '취업 경쟁력을 높여줄 225시간',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/backendplus_pc2.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/backendplus_m2.png',
    },
  },
  {
    id: 'aiw',
    label: 'AI 웹 서비스 개발',
    active: false,
    bgColor: '#FCF1E9',
    subTitle: 'AI 개발자로 새로운 시작',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/aiw_pc.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/aiw_m.png',
    },
  },

  {
    id: 'ugm',
    label: '유니티 게임 개발',
    active: false,
    bgColor: '#E8E8E8',
    subTitle: '게임 개발자로 거듭나는 여정',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/ugm_pc.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/ugm_m.png',
    },
  },
  {
    id: 'cld',
    label: '클라우드 엔지니어링',
    active: false,
    bgColor: '#FCF1E9',
    subTitle: '클라우드 전문가로 도약',
    thumbnails: {
      pc: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/cld_pc.png',
      mobile: 'https://d35ai18pny966l.cloudfront.net/course/kdt/schedule/cld_m.png',
    },
  },
]

export const AdditionalList: IAdditionalList = {
  kdt: [
    {
      id: 'addition1',
      icon: '/svg/icon_card.svg',
      title: '전액 지원 부트캠프',
      boldTitle: '내일배움카드 발급 대상',
      summary: '국민내일배움카드 발급 가능한 대상만 지원 가능',
      desc:
        '하며 빠르게 카드 발급 자격을 확인하려면 고용노동부 1350을 통해 대상 여부를 확인할 수 있어요.',
      boldDesc: '수강 기간 전까지 카드를 수령하셔야 수강이 가능',
      reference: '* 최근 5년 이내 KDT 과정을 수강한 경우 참여가 어려워요.',
      isOpen: false,
    },
    {
      id: 'addition2',
      icon: '/svg/icon_computer.svg',
      title: '취업 교육과정',
      boldTitle: '100% 온라인 실시간 라이브',
      desc: '되는 교육 과정을 참여할 수 있어야 과정 참여가 가능해요.',
      boldDesc: '주 5일, 09:00~18:00 (월~금) 진행',
      isOpen: false,
    },
  ],
  kdt_plus: [
    {
      id: 'addition3',
      icon: '/svg/icon_card.svg',
      title: '전액 지원 부트캠프',
      boldTitle: '내일배움카드 발급 대상',
      summary: '국민내일배움카드 발급 가능한 대상만 지원 가능',
      desc: '으로 단기 심화과정을 최초에 참여한 수강생만 참여가 가능합니다.',
      boldDesc: '취업 경쟁력을 높여줄 오프라인 프로젝트 중심의 단기과정',
      isOpen: false,
    },
    {
      id: 'addition4',
      icon: '/svg/icon_bookmark.svg',
      title: '프로젝트 중심 과정',
      boldTitle: '주 5일 풀타임 오프라인',
      desc: '되며 동대문 교육장으로 통학이 가능한 분들만 참여가 가능해요.',
      boldDesc: '주 5일, 09:30~18:00 (월~금) 9주에 걸쳐 진행',
      isOpen: false,
    },
  ],
  kdt_startup: [
    {
      id: 'addition5',
      icon: '/svg/icon_card.svg',
      title: '전액 지원 부트캠프',
      boldTitle: '내일배움카드 발급 대상',
      summary: '국민내일배움카드 발급 가능한 대상만 지원 가능',
      desc:
        '하며 빠르게 카드 발급 자격을 확인하려면 고용노동부 1350을 통해 대상 여부를 확인할 수 있어요.',
      boldDesc: '수강 시작 전까지 카드를 수령하셔야 수강이 가능',
      reference: '* 최근 5년 이내 KDT 과정을 수강한 경우 참여가 어려워요.',
      isOpen: false,
    },
    {
      id: 'addition6',
      icon: '/svg/icon_bagcase.svg',
      title: 'IT창업 온보딩 과정',
      boldTitle: '100% 오프라인',
      desc: '되며 동대문 교육장으로 통학이 가능한 분들만 참여가 가능해요.',
      boldDesc: '주 5일, 09:30~18:00 (월~금) 15주에 걸쳐 진행',
      isOpen: false,
    },
  ],
  kdc: [
    {
      id: 'addition7',
      icon: '/svg/icon_card.svg',
      title: '',
      boldTitle: '내일배움카드 크레딧으로 수강료 90% 지원',
      summary: 'K-디지털 기초역량훈련 전용 크레딧이란?',
      desc: 'KDT 지원금과는 별도로 제공되는 지원금이에요. 50만원의 크레딧이 기본으로 제공돼요.',
      boldDesc: '',
      isOpen: false,
      secondSummary: '내 크레딧 확인 방법',
      secondDesc:
        'HRD-Net 로그인 > 나의 정보 > 나의 카드 > 카드사용내역 > K-디지털 기초역량훈련 사용액 확인',
    },
    {
      id: 'addition8',
      icon: '/svg/icon_coin.svg',
      title: '',
      boldTitle: '크레딧이 1원만 남아있어도 수강 신청 가능',
      desc:
        '크레딧 지원금이 1원만 남아있어도 6주완성 수강이 가능해요. 최대 2개 강의까지 수강 가능하니 각 강의별로 수강 신청을 해주세요.',
      boldDesc: '',
      reference: '* 크레딧은 최초 사용일로부터 1년 이내에 사용이 가능해요.',
      isOpen: false,
    },
  ],
}

interface IAdditionalList {
  [courseType: string]: IAdditionalItem[]
}
export interface IAdditionalItem {
  id: string
  icon: string
  title: string
  boldTitle: string
  summary?: string
  desc: string
  boldDesc: string
  reference?: string
  isOpen: boolean
  secondSummary?: string
  secondDesc?: string
}
